import React from 'react'
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import {Link} from 'gatsby'
import "../../components/fontawesome.css"
import LeftMenu from './leftMenu'

const Judging = () => {
  return (
    <Layout>
    <SEO title="National Student Essay Competition | Judging Criteria" />

    <div className="subpageContainer">

      <LeftMenu active="judging" />
      <div className="body">
         
        <h2 style={{marginTop: '55px'}}>How Essays Will Be Judged</h2>

          <ul>
            <li>By a <Link to="/national-student-essay-competition/jury">distinguished jury</Link> $5K in each category</li>
            <li>Overall ranking by the jury for the 4-year college scholarship - </li>
            <li>Popular vote on the website - <a href="https://forms.gle/PyxmoiS6KHSsKGiW8" target="_blank">why a popular vote and how to vote</a></li>
          </ul>

          <p>The essays submitted by students, sixth grade to university and college, were impressively thoughtful, well written and quite powerful in defense of the First Amendment and the vital importance of a free press in our country. We want to share these earnest, persuasive voices with as many of our fellow citizens, of all ages and of all backgrounds, as possible. To that end we invite the public, in addition to our distinguished jury, to vote on the finalist essays. The winner of the popular vote will be announced later in July when the Jury announces its winning essayists and will receive a check for $1000 from MKL Public Relations. </p>
        

        </div>
      </div>
    </Layout>
  )
}

export default Judging
