import React from 'react'
import {Link} from 'gatsby'

const LeftMenu = props => (
  <div className="leftMenu">
    <ul>
      <li className={(props.active === 'home')? 'active' : ''}><Link to="/national-student-essay-competition">Student Essay Competition</Link></li>
      <li className={(props.active === 'winners')? 'active' : ''}><Link to="/national-student-essay-competition/winners">Winners</Link></li>
      <li className={(props.active === 'sponsors')? 'active' : ''}><Link to="/national-student-essay-competition/sponsors">Sponsors</Link></li>
      <li className={(props.active === 'jury')? 'active' : ''}><Link to="/national-student-essay-competition/jury">Jury</Link></li>
      <li className={(props.active === 'advisory-committee')? 'active' : ''}><Link to="/national-student-essay-competition/advisory-committee">Honorary Advisory Committee</Link></li>      
      <li className={(props.active === 'guidelines-and-timeline')? 'active' : ''}><Link to="/national-student-essay-competition/guidelines-and-timeline">Guidelines &amp; Timeline</Link></li>
      <li className={(props.active === 'awards')? 'active' : ''}><Link to="/national-student-essay-competition/awards">Awards &amp; Prizes</Link></li>
      <li className={(props.active === 'participating-news-outlets')? 'active' : ''}><Link to="/national-student-essay-competition/participating-news-outlets">Participating News Outlets</Link></li>
      <li className={(props.active === 'contact')? 'active' : ''}><Link to="/national-student-essay-competition/contact">Contact</Link></li>
    </ul>
  </div>
)

export default LeftMenu
